import { useTheme, styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const Root = styled(Box)(({ theme }) => ({
  marginTop: '64px',
}));

const DefaultPageLayout = ({ children }) => {
  const theme = useTheme();
  
  return (
    <Root theme={theme}>
      {children}
    </Root>
  );
};

export default DefaultPageLayout;