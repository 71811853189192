import { styled } from '@mui/material/styles';
import { Box, Container, Typography,  } from '@mui/material';
import NavigationBar  from '../../components/DefaultNavigationBar';
import PageLayout     from '../../components/DefaultPageLayout'; 

const Root = styled(Box)(({ theme }) => ({
  '& .page-container': {
    paddingTop: '45px',
    paddingBottom: '45px',
  },
  '& table': {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: theme.palette.common.white,
    borderCollapse: 'collapse',
  },
  '& table > thead > tr > td': {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: theme.palette.common.white,
    backgroundColor: theme.palette.primary.light,
    padding: '5px 10px',
    fontWeight: 600,
  },
  '& table > tbody > tr > td': {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: theme.palette.common.white,
    backgroundColor: theme.palette.primary.light,
    padding: '5px 10px',
    fontWeight: 400,
  },
  '& table > tbody > tr:nth-child(odd) > td': {
    backgroundColor: theme.palette.primary.lightTableStripe,
  },
}));

const ApiDocPage = ({ title, children }) => {
  return (
    <Root>
      <NavigationBar />
      <PageLayout>
        <Container className="page-container">
          <Typography variant="h1">{title}</Typography>
          {children}
        </Container>
      </PageLayout>
    </Root>
  );
};

export default ApiDocPage;