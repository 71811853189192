import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Container, Typography, Card, CardContent, CardActions, Button, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import NavigationBar from '../../components/DefaultNavigationBar';
import PageLayout from '../../components/DefaultPageLayout';
import { margin } from '@mui/system';

const Root = styled(Box)(({ theme }) => ({
  '& .hero-container': {
    paddingTop: '75px',
    paddingBottom: '75px',
    backgroundColor: theme.palette.primary.light,
  },
  '& .hero-container > h1': {
    display: 'block',
    maxWidth: '800px',
    margin: 'auto',
    textAlign: 'center',
    color: theme.palette.primary.dark,
    fontSize: '46px',
    lineHeight: '48px',
    fontWeight: 300,
  },
  '& .hero-container > h2': {
    display: 'block',
    maxWidth: '700px',
    margin: 'auto',
    marginTop: '30px',
    textAlign: 'center',
    color: theme.palette.primary.dark,
    fontSize: '20px',
    lineHeight: 1.3,
    fontWeight: 400,
  },
  '& .cards-container': {
    marginTop: '50px',
  },
  '& .card': {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  '& .card-content': {
    flexGrow: 1,
  },
  '& .card-content > .card-title': {
    color: theme.palette.primary.dark,
    marginTop: '0px',
  },
  '& .card-content > .card-description': {
    color: theme.palette.primary.dark,
  },
  '& .card-actions': {
    padding: '16px',
  },
  '& .card-actions > .card-action': {
    color: theme.palette.primary.dark,
    backgroundColor: theme.palette.primary.light,
    paddingLeft: '10px',
    paddingRight: '10px',
  }
}));

const apis = [
  {
    title: "Elevation API",
    description: "Provides elevation data for a location using latitude and longitude. Supports multiple locations in a single request. High-resolution terrain models available for specific countries.",
    link: "/apis/elevation"
  },
  {
    title: "Mission Weather Impact Safety Analysis API",
    description: "Assesment of the weather risks for drone missions using weather data, flight plans, and drone limitations. Delivers detailed reports and impact levels (green, amber, red).",
    link: "/apis/mission-safety-analysis"
  },
  {
    title: "Weather Forecast Data API",
    description: "Offers detailed weather data for a specific date and time. Supports point or area queries. Includes temperature, pressure, humidity, wind, visibility, and more.",
    link: "/apis/weather-forecast-data"
  },
  {
    title: "Metar & TAF Data API",
    description: "Returns METARs and TAFs for airports worldwide. Supports ICAO airport ID or area queries. AIRMETs and SIGMETs are in development.",
    link: "apis/metar-taf-data"
  }
];

const Home = () => {
  return (
    <Root>
      <NavigationBar />
      <PageLayout>
        <Container className="hero-container" maxWidth={null}>
          <Typography variant="h1" gutterBottom>
            Atmosoar API Documentation
          </Typography>
          <Container className="cards-container" maxWidth="lg">
            <Grid container spacing={4}>
              {apis.map((api, index) => (
                <Grid item xs={12} md={6} key={index}>
                  <Card className="card">
                    <CardContent className="card-content">
                      <Typography variant="h5" component="div" className="card-title">
                        <strong>{api.title}</strong>
                      </Typography>
                      <Typography variant="body2" className="card-description">
                        {api.description}
                      </Typography>
                    </CardContent>
                    <CardActions className="card-actions">
                      <Button size="small" component={Link} to={api.link} className="card-action">Learn More</Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
        </Container>
      </PageLayout>
    </Root>
  );
};

export default Home;
