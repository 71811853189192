import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import { Button, Typography } from '@mui/material';
import { Link } from 'react-router-dom';  // Import Link from react-router-dom

function DefaultNavigationBar({ children }) {
  return (
    <AppBar position="fixed">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexGrow: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Button href="/">
                <img src="/favicon.png" alt="Logo" style={{height: '50px'}} />
                <Typography variant="h6" sx={{ marginLeft: 2, fontWeight: 400, color:'white' }}>
                  ATMOSOAR
                </Typography>
              </Button>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {/*
              <Button component={Link} to="/apis" sx={{ marginLeft: 2, color: 'white', textTransform:'unset' }}>
                  Explore our APIs
                </Button>
              <Button component={Link} to="/apis/metar-taf-data" sx={{ marginLeft: 2, color: 'white' }}>
                Metar & Taf
              </Button>
              <Button component={Link} to="/apis/weather-forecast-data" sx={{ marginLeft: 2, color: 'white' }}>
                Weather Forecast Data API
              </Button>
              <Button component={Link} to="/apis/mission-safety-analysis" sx={{ marginLeft: 2, color: 'white' }}>
                Mission Weather Impact Safety Analysis
              </Button>
              <Button component={Link} to="/apis/elevation" sx={{ marginLeft: 2, color: 'white' }}>
                Elevation API
              </Button>
              */}
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default DefaultNavigationBar;